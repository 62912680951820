
import { useProfile } from '@/composables/profile/useProfile'
import { computed, defineComponent, ref, unref, watch } from 'vue'
import { currentUser } from '@/composables/user/currentUser'
import CaptionPreview from './CaptionPreview.vue'

export default defineComponent({
  components: { CaptionPreview },
  props: {
    thumbnail: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    isValid: {
      type: Boolean,
      default: false
    },
    isLoadingCreative: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { profile } = useProfile()

    const { user } = currentUser()

    const location = ref('')

    const profilePick = computed(() => {
      return unref(profile).profilePick ? unref(profile).profilePick : ''
    })

    const profileName = computed(() => {
      return unref(profile).name
    })

    const hasContent = computed(() => {
      return !!props.content.trim().length
    })

    watch(user, (currentUser) => {
      if (currentUser && currentUser.billing && currentUser.billing.address) {
        const { district, city } = currentUser.billing.address
        location.value = `${district}, ${city}`
      }
    })

    const addDefaultSrc = (e: Event) => {
      const target = e.target as HTMLImageElement
      if (target) {
        target.src = require('@/assets/img/no-image.png')
      }
    }

    return {
      addDefaultSrc,
      profilePick,
      profileName,
      location,
      hasContent
    }
  }
})
